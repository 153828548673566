import D from "\u0040\u006d\u0075\u0069\u002f\u006d\u0061\u0074\u0065\u0072\u0069\u0061\u006c\u002f\u0044\u0069\u0061\u006c\u006f\u0067";
import DC from "\u0040\u006d\u0075\u0069\u002f\u006d\u0061\u0074\u0065\u0072\u0069\u0061\u006c\u002f\u0044\u0069\u0061\u006c\u006f\u0067\u0043\u006f\u006e\u0074\u0065\u006e\u0074";
import m from "\u0072\u0065\u0061\u0063\u0074";
import * as g from "\u0040\u0073\u0065\u006d\u0061\u0074\u0069\u0063\u002f\u0063\u006f\u006d\u006d\u006f\u006e\u002f\u0073\u0072\u0063\u002f\u0075\u0074\u0069\u006c\u0073\u002f\u0046\u0065\u0061\u0074\u0075\u0072\u0065\u0046\u006c\u0061\u0067\u004d\u0061\u006e\u0061\u0067\u0065\u0072";
const k=require("\u006b\u006f\u006e\u0061\u006d\u0069"),w=window.localStorage,l="\u0073\u0065\u006d\u0061\u0074\u0069\u0063\u002d\u0066\u0065\u0061\u0074\u0075\u0072\u0065\u002d\u0066\u006c\u0061\u0067\u002d\u006e\u0065\u0077\u0075\u0069"
    ,us=m["\u0075\u0073\u0065\u0045\u0066\u0066\u0065\u0063\u0074"],ss=m["\u0075\u0073\u0065\u0053\u0074\u0061\u0074\u0065"],gg=g["\u0067\u0065\u0074\u0046\u0065\u0061\u0074\u0075\u0072\u0065\u0046\u006c\u0061\u0067\u0056\u0061\u006c\u0075\u0065"];
function K(){const [p,p1]=ss(false);const [tr,bb]=ss(gg("\u006e\u0065\u0077\u0075\u0069")||false);
    const eC=()=>{ p1(false);};const eh=()=>{bb(!tr);if (!tr) {w["\u0073\u0065\u0074\u0049\u0074\u0065\u006d"](l,(!tr).toString());}else{
        w["\u0072\u0065\u006d\u006f\u0076\u0065\u0049\u0074\u0065\u006d"](l);}};us(()=>{new k(()=>{p1(true);});},[]);
    return <D open={p}onClose={eC}><DC><input type={"\u0063\u0068\u0065\u0063\u006b\u0062\u006f\u0078"}checked={tr}onChange={eh}/><label>{"\u0045\u006e\u0061\u0062\u006c\u0065\u0020\u006e\u0065\u0077\u0020\u0055\u0049"}</label></DC></D>}
export default K;