const s3 = ({style}: {style: React.CSSProperties | undefined} ) => <svg xmlns="http://www.w3.org/2000/svg" style={style} enableBackground="new 0 0 100 100" viewBox="0 0 100 100" id="amazon-s3">
    <polygon fill="#8C3123" points="50.329 67.354 75.312 73.399 75.312 26.492 50.329 32.541" />
    <polygon fill="#E05243" points="75.313 26.492 80.114 28.892 80.114 71.013 75.313 73.412" />
    <polygon fill="#E05243" points="61.226 34.856 50.329 32.16 50.329 14 61.226 19.448" />
    <polygon fill="#E05243" points="50.329 86 61.225 80.554 61.225 65.146 50.329 67.841" />
    <polygon fill="#E05243" points="61.226 57.714 50.329 59.101 50.329 40.94 61.226 42.307" />
    <polygon fill="#E05243" points="50.329 67.354 25.345 73.399 25.345 26.492 50.329 32.541" />
    <polygon fill="#8C3123" points="25.345 26.492 20.546 28.892 20.546 71.013 25.345 73.412" />
    <polygon fill="#8C3123" points="39.435 34.856 50.329 32.16 50.329 14 39.435 19.448" />
    <polygon fill="#8C3123" points="50.329 86 39.434 80.554 39.434 65.146 50.329 67.841" />
    <polygon fill="#8C3123" points="39.435 57.714 50.329 59.101 50.329 40.94 39.435 42.307" />
    <polygon fill="#5E1F18" points="61.226 34.856 50.329 36.842 39.435 34.856 50.329 32.16" />
    <polygon fill="#F2B0A9" points="61.226 65.145 50.329 63.146 39.435 65.145 50.329 67.858" />
</svg>
export default s3;